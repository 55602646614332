export default [
    /*
    {
        path: '/task',
        name: 'task',
        component: () => import('@/views/todo/Todo.vue'),
        meta:{
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
            resource: 'tasks',
            action: 'read'
        }
    },
    */
   {
       path: '/tasks',
       name: 'tasks',
       component: () => import('@/views/task/tasks-list/TasksList.vue'),
       meta:{
            resource:'Auth',
            action:'read'
       }
   },
   {
    path: '/tasks/edit/:id',
    name: 'tasks-edit',
    component: () => import('@/views/task/tasks-edit/TasksEdit.vue'),
    meta:{
      action: 'read',
      resource: 'Auth',
    }
  },
  {
    path: '/admin/tasks',
    name: 'admin-tasks',
    component: () => import('@/views/task/tasks-list/TasksList.vue'),
    meta:{
        resource:'tasks',
        action:'read'
    }
},
]