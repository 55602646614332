import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import CKEditor from 'ckeditor4-vue'
import {getCorrectDateTime} from '@/libs/helper'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/acl'
import '@/libs/sweet-alerts'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'

import 'element-ui/lib/theme-chalk/index.css';
import FileManager from 'laravel-file-manager'
import VueClipboard from 'vue-clipboard2';

Vue.prototype.getCorrectDateTime = getCorrectDateTime

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(ElementUI, { locale })
Vue.use(CKEditor)
Vue.use(FileManager, {store});
// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueClipboard);

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
