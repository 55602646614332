export default [
  {
    path: "/receipt/template/list",
    name: "receipt-template-list",
    component: () =>
      import("@/views/payment/receipt-templates/ReceiptTemplatesList.vue"),
    meta: {
      action: "read",
      resource: "invoice-templates",
    },
  },
  {
    path: "/invoice/template/list",
    name: "invoice-template-list",
    component: () =>
      import("@/views/invoice/invoice-templates/InvoiceTemplatesList.vue"),
    meta: {
      action: "read",
      resource: "invoice-templates",
      text: "合約模版",
    },
  },
  {
    path: "/invoices/payments/list",
    name: "payments-list",
    component: () => import("@/views/payment/payments-list/PaymentsList.vue"),
    meta: {
      resource: "payments",
      action: "read",
    },
  },
  {
    path: "/invoices/paymentaccounts/list",
    name: "paymentaccounts-list",
    component: () =>
      import("@/views/invoice/paymentaccounts-list/PaymentAccountsList.vue"),
    meta: {
      resource: "paymentaccounts",
      action: "read",
    },
  },
  {
    path: "/invoices/paymentaccounts/edit/:id",
    name: "paymentaccounts-edit",
    component: () =>
      import("@/views/invoice/paymentaccounts-edit/PaymentAccountsEdit.vue"),
    meta: {
      action: "update",
      resource: "paymentaccounts",
    },
  },
  {
    path: "/invoices/paymentaccounts/create",
    name: "paymentaccounts-create",
    component: () =>
      import("@/views/invoice/paymentaccounts-edit/PaymentAccountsEdit.vue"),
    meta: {
      action: "create",
      resource: "clients",
    },
  },
  {
    path: "/invoices/list",
    name: "invoices-list",
    component: () => import("@/views/invoice/invoices-list/InvoicesList.vue"),
    meta: {
      resource: "invoices",
      action: "read",
    },
  },
  {
    path: "/invoices/add",
    name: "invoices-add",
    component: () => import("@/views/invoice/invoices-add/InvoicesAdd.vue"),
    meta: {
      resource: "invoices",
      action: "create",
    },
  },
  {
    path: "/invoices/edit/:id",
    name: "invoices-edit",
    component: () => import("@/views/invoice/invoices-add/InvoicesAdd.vue"),
    meta: {
      resource: "invoices",
      action: "create",
    },
  },
  {
    path: "/invoices/preview/:id",
    name: "invoices-preview",
    component: () =>
      import("@/views/invoice/invoices-preview/InvoicesPreview.vue"),
    meta: {
      layout: "full",
      resource: "invoices",
      action: "read",
    },
  },
];
