export function getNextCycleEndDate(startDateStr) {

    if(!startDateStr) return;
    // 將開始日期轉換成 Date 物件
    const startDate = new Date(startDateStr);
  
    // 計算下一個周期的年份
    const nextYear = startDate.getFullYear() + 1;
  
    // 將日期設為下一年的日期，再減去一天
    const nextCycleEnd = new Date(nextYear, startDate.getMonth(), startDate.getDate());
    nextCycleEnd.setDate(nextCycleEnd.getDate());
  
    // 回傳下一個周期的結束日期
    return nextCycleEnd.toISOString().substring(0, 10);
  }
  
  export function getPreviousYear(dateStr) {
    // 將輸入日期轉換成 Date 物件
    if(!dateStr) return;
    const date = new Date(dateStr);
  
    // 取得輸入日期的年份，並減去一年
    const previousYear = date.getFullYear() - 1;
  
    // 設定日期的年份為前一年的年份
    date.setFullYear(previousYear);
  
    // 回傳減一年後的日期
    return date.toISOString().substring(0, 10);
  }

  export function getNextYear(dateStr){
    // 將輸入日期轉換成 Date 物件
    const date = new Date(dateStr);
  
    // 取得輸入日期的年份，並減去一年
    const previousYear = date.getFullYear() + 1;
  
    // 設定日期的年份為前一年的年份
    date.setFullYear(previousYear);
  
    // 回傳減一年後的日期
    return date.toISOString().substring(0, 10);
  }

  export function dateDifferent(dateFrom, dateTo)
  {
    const date1 = new Date(dateFrom);
    const date2 = new Date(dateTo);
    const diffTime = (date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays;
  }

  export function getDateWithoutTime(dateStr) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  export function convertToLocalDate(isoDatetime) {
    // Create a new Date object using the ISO datetime string
    var date = new Date(isoDatetime);

    // Convert the Date object to a local datetime string
    var localDatetime = date.toLocaleString();

    // Return the local datetime string
    return localDatetime;
}
  
export function getCorrectDateTime(datetime, withoutTime)
{
    let utcDate
    if(!datetime) return 
    
    if( datetime instanceof Date ){
        utcDate = datetime;
    }else if(datetime.indexOf('Z') > -1){
        utcDate = new Date(datetime.slice(0,19));
    }else{
        utcDate = new Date(datetime);
    }

    // Get the timezone offset in minutes
    const timezoneOffset = new Date().getTimezoneOffset();

    // Convert the offset to milliseconds
    const offsetInMs = timezoneOffset * 60 * 1000;

    // Add the offset to the UTC time
    const localTime = new Date(utcDate.getTime() - offsetInMs);
 
    // Format the local time as a string in the YYYY-MM-DD HH:MM:SS format
    const year = localTime.getFullYear();
    const month = String(localTime.getMonth() + 1).padStart(2, '0');
    const day = String(localTime.getDate()).padStart(2, '0');
    const hours = String(localTime.getHours()).padStart(2, '0');
    const minutes = String(localTime.getMinutes()).padStart(2, '0');
    const seconds = String(localTime.getSeconds()).padStart(2, '0');
    let localTimeString
    if(withoutTime){
        localTimeString = `${year}-${month}-${day}`;
    }else{
        localTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return localTimeString;
}