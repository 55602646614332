export default [
    {
        path: '/clients/list',
        name: 'clients-list',
        component: () => import('@/views/client/client/clients-list/ClientsList.vue'),
        meta: {
            resource: 'clients',
            action: 'read'
        }

    },
    {
        path: '/clients/groupcodes/list',
        name: 'groupcodes-list',
        component: () => import('@/views/client/groupcode/groupcodes-list/GroupCodesList.vue'),
        meta: {
            resource: 'groupcodes',
            action: 'read'
        },
    },
    {
        path:'/clients/create',
        name: 'clients-create',
        component:() => import('@/views/client/client/clients-edit/ClientsEdit.vue'),
        meta:{
        action: 'create',
        resource: 'clients'
        }
    },
    {
        path: '/clients/edit/:id',
        name: 'clients-edit',
        component: () => import('@/views/client/client/clients-edit/ClientsEdit.vue'),
        meta:{
          action: 'update',
          resource: 'clients'
        }
      },
      {
        path: '/clients/detail/:id',
        name: 'clients-detail',
        component: () => import('@/views/client/client/clients-detail/ClientsDetail.vue'),
        meta:{
          action: 'read',
          resource: 'clients'
        }
      },
    
]