export default [
 
    // *===============================================---*
    // *--------- USER ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/leaverecords/list',
      name: 'leaverecords-list',
      component: () => import('@/views/admin/leaverecord/leaverecords-list/LeaveRecordsList.vue'),
      meta:{
        action: 'read',
        resource: 'leaverecords',
      }
    },
    {
      path: '/overtimes/list',
      name: 'overtimes-list',
      component: () => import('@/views/admin/overtime/overtimes-list/OvertimesList.vue'),
      meta:{
        action: 'read',
        resource: 'overtimes',
      }
    },
    {
      path: '/works/list',
      name: 'works-list',
      component: () => import('@/views/admin/work/works-list/WorksList.vue'),
      meta:{
        action: 'read',
        resource: 'works',
      }
    },
    {
      path: '/works/create',
      name: 'works-create',
      component:() => import('@/views/dashboard/work/works-edit/WorksEdit.vue'),
      meta:{
        action: 'read',
        resource: 'Auth',
      }
    },
    {
      path: '/works/edit/:id',
      name: 'works-edit',
      component: () => import('@/views/dashboard/work/works-edit/WorksEdit.vue'),
      meta:{
        action: 'read',
        resource: 'Auth',
      }
    },
    {
      path: '/logs/list',
      name: 'logs-list',
      component: () => import('@/views/log/logs-list/LogsList.vue'),
      meta:{
        action: 'read',
        resource: 'logs',
      }
    },
  ]