export default [
 
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/user/users-list/UsersList.vue'),
    meta:{
      action: 'read',
      resource: 'users',
    }
  },
  {
    path: '/users/view/:id',
    name: 'users-view',
    component: () => import('@/views/user/users-view/UsersView.vue'),
    meta:{
      action: 'read',
      resource: 'users',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/user/users-edit/UsersEdit.vue'),
    meta:{
      action: 'update',
      resource: 'users'
    }
  },
  { 
    path:'/users/create',
    name: 'users-create',
    component:() => import('@/views/user/users-edit/UsersEdit.vue'),
    meta:{
      action: 'create',
      resource: 'users'
    }

  },

  // *===============================================---*
  // *--------- TEAM ---------------------------------------*
  // *===============================================---*

  {
    path:'/teams/list',
    name: 'teams-list',
    component:() => import('@/views/team/teams-list/TeamsList.vue'),
    meta:{
      action: 'read',
      resource: 'teams'
    }
  },
  {
    path:'/teams/edit/:id',
    name:'teams-edit',
    component: () => import('@/views/team/teams-edit/TeamsEdit.vue'),
    meta:{
      action: 'update',
      resource: 'teams'
    }
  },

  // *===============================================---*
  // *--------- ROLE ---------------------------------------*
  // *===============================================---*
  {
    path:'/roles/list',
    name: 'roles-list',
    component:() => import('@/views/role/roles-list/RolesList.vue'),
    meta:{
      action: 'read',
      resource: 'roles'
    }
  },
  {
    path:'/roles/edit/:id',
    name: 'roles-edit',
    component:() => import('@/views/role/roles-edit/RolesEdit.vue'),
    meta:{
      action: 'read',
      resource: 'roles'
    }
  }



]