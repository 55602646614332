export default [
    {
        path: '/quotations/list',
        name: 'quotations-list',
        component: () => import('@/views/quotation/quotations-list/QuotationsList.vue'),
        meta: {
            resource: 'quotations',
            action: 'read'
        }

    },
    {
        path: '/quotations/add',
        name: 'quotations-add',
        component: () => import('@/views/quotation/quotations-add/QuotationsAdd.vue'),
        meta: {
            resource: 'quotations',
            action: 'create'
        }
    },
    {
        path: '/quotations/edit/:id',
        name: 'quotations-edit',
        component: () => import('@/views/quotation/quotations-add/QuotationsAdd.vue'),
        meta: {
            resource: 'quotations',
            action: 'create'
        }
    },
    {
        path: '/quotations/preview/:id',
        name: 'quotations-preview',
        component: () => import('@/views/quotation/quotations-preview/QuotationsPreview.vue'),
        meta: {
          layout: 'full',
          resource: 'quotations',
          action: 'read'
        },
    },
    
]