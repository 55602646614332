export default[
    {
        path: '/meeting',
        name: 'meetings',
        component: () => import('@/views/meeting/Meeting.vue'),
        meta:{
            resource: 'Auth',
            action: 'read'
        }
    },
    {
        path: '/admin/meeting',
        name: 'admin-meetings',
        component: () => import('@/views/meeting/Meeting.vue'),
        meta:{
            resource: 'meetings',
            action: 'read'
        }
    }
]