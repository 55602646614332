import axiosIns from '@/libs/axios'

export default {
    namespaced: true,
    state:{
        user: {}
    },
    getters:{
        getUser: state => {
            return state.user
        }
    },
    mutations:{
        UPDATE_USER(state, val) {
            state.user = val
        }
    },
    actions:{
        fetchUser({ commit }, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get(`/auth/user`, {params:queryParams})
                .then(response => 
                {
                    commit('UPDATE_USER', response.data.user)
                    resolve(response)
                }       
                )
                .catch(error => reject(error));
            })
        }
    }
}