export default [
  // ------------------------------News--------------------------------------
  {
    path: "/news/list",
    name: "news-list",
    component: () => import("@/views/pages/news/news-list/NewsList.vue"),
    meta: {
      resource: "news",
      action: "read",
    },
  },
  {
    path: "/news/create",
    name: "news-create",
    component: () => import("@/views/pages/news/news-edit/NewsEdit.vue"),
    meta: {
      action: "create",
      resource: "news",
    },
  },
  {
    path: "/news/edit/:id",
    name: "news-edit",
    component: () => import("@/views/pages/news/news-edit/NewsEdit.vue"),
    meta: {
      action: "update",
      resource: "news",
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/',
    name: "login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/dashboard',
    name: 'home',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      action: 'read',
      resource: 'Auth',
    },
  },
  {
    path: "/forgot",
    name: "auth-forgot-password",
    component: () =>
      import("@/views/pages/authentication/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
  {
    path: "/pages/not-authorized",
    name: "not-authorized",
    // ! Update import path
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
    meta: {
      layout: "full",
      action: "read",
      resource: "Auth",
    },
  },
];
