export default [
  {
    path: '/contracts/list',
    name: 'contracts-list',
    component: () => import('@/views/contract/contracts-list/ContractsList.vue'),
    meta: {
        resource: 'contracts',
        action: 'read'
    }

},
  {
    path: "/contract/template/list",
    name: "contract-template-list",
    component: () =>
      import("@/views/contract/contract-templates/ContractTemplatesList.vue"),
    meta: {
      action: "read",
      resource: "contract-templates",
      text: "合約模版",
    },
  },
  {
    path: "/contracts/demo/preview/:id",
    name: "contracts-demo-preview",
    component: () =>
      import("@/views/template/contract_template/ContractPreview.vue"),
    meta: {
      layout: "full",
      resource: "contracttemplates",
      action: "read",
    },
  },
  {
    path: "/contracts/preview/:id",
    name: "contracts-preview",
    component: () => import("@/views/template/contract/ContractPreview.vue"),
    meta: {
      layout: "full",
      resource: "contracttemplates",
      action: "read",
    },
  },
];
