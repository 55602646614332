export default [
    {
        path: '/projects/list',
        name: 'projects-list',
        component: () => import('@/views/project/projects-list/ProjectsList.vue'),
        meta: {
            resource: 'projects',
            action: 'read'
        }

    },
]