export default [
    {
        path: '/settings/document-types',
        name: 'settings-document-types',
        component: () => import('@/views/setting/documenttype/documenttypes-list/DocumentTypesList.vue'),
        meta: {
            resource: 'documenttypes',
            action: 'read'
        },
    },
    {
        path: '/settings/prefixes',
        name: 'settings-prefixes',
        component: () => import('@/views/setting/prefix/prefix-edit/prefixEdit.vue'),
        meta: {
            resource: 'prefixes',
            action: 'read'
        },
    },
    {
        path: '/settings/invoice-remarks',
        name: 'invoice-remarks',
        component: () => import('@/views/setting/invoiceremark/invoiceremarks-list/InvoiceRemarksList.vue'),
        meta: {
            resource: 'invoice-remarks',
            action: 'read'
        },
    },
    {
        path: '/settings/payment-remarks',
        name: 'payment-remarks',
        component: () => import('@/views/setting/paymentremark/paymentremarks-list/PaymentRemarksList.vue'),
        meta: {
            resource: 'payment-remarks',
            action: 'read'
        },
    },
    {
        path: '/settings/product/types',
        name: 'product-types',
        component: () => import('@/views/setting/product/type/types-list/TypesList.vue'),
        meta: {
            resource: 'product-types',
            action: 'read'
        },
    },
    {
        path: '/settings/product/brands',
        name: 'product-brands',
        component: () => import('@/views/setting/product/brand/brands-list/BrandsList.vue'),
        meta: {
            resource: 'product-brands',
            action: 'read'
        },
    },
    {
        path: '/settings/servicecodes/list',
        name: 'servicecodes-list',
        component: () => import('@/views/setting/servicecode/servicecodes-list/ServiceCodesList.vue'),
        meta: {
            resource: 'servicecodes',
            action: 'read'
        },
    },
    {
        path: '/settings/projectstages/list',
        name: 'projectstages-list',
        component: () => import('@/views/setting/projectstage/projectstages-list/ProjectStagesList.vue'),
        meta: {
            resource: 'projectstages',
            action: 'read'
        },
    },
    {
        path: '/settings/taskstages/list',
        name: 'taskstages-list',
        component: () => import('@/views/setting/taskstage/taskstages-list/TaskStagesList.vue'),
        meta: {
            resource: 'taskstages',
            action: 'read'
        },
    },
    {
        path: '/settings/tasktags/list',
        name: 'tasktags-list',
        component: () => import('@/views/setting/tasktag/tasktags-list/TaskTagsList.vue'),
        meta: {
            resource: 'tasktags',
            action: 'read'
        },
    },
    {
        path: '/settings/meetingstages/list',
        name: 'meetingstages-list',
        component: () => import('@/views/setting/meetingstage/meetingstages-list/MeetingStagesList.vue'),
        meta: {
            resource: 'meetingstages',
            action: 'read'
        },
    },
    {
        path: '/settings/meetingtags/list',
        name: 'meetingtags-list',
        component: () => import('@/views/setting/meetingtag/meetingtags-list/MeetingTagsList.vue'),
        meta: {
            resource: 'meetingtags',
            action: 'read'
        },
    },
    {
        path: '/settings/applicationcodes/list',
        name: 'applicationcodes-list',
        component: () => import('@/views/setting/applicationcode/applicationcodes-list/ApplicationCodesList.vue'),
        meta: {
            resource: 'applicationcodes',
            action: 'read'
        },
    },
    {
        path: '/settings/leavecodes/list',
        name: 'leavecodes-list',
        component: () => import('@/views/setting/leavecode/leavecodes-list/LeaveCodesList.vue'),
        meta: {
            resource: 'leavecodes',
            action: 'read'
        },
    },
    {
        path: '/settings/positions/list',
        name: 'positions-list',
        component: () => import('@/views/setting/position/positions-list/PositionsList.vue'),
        meta: {
            resource: 'positions',
            action: 'read'
        },
    },
    {
        path: '/settings/holidays/list',
        name: 'holidays-list',
        component: () => import('@/views/setting/holiday/holidays-list/HolidaysList.vue'),
        meta: {
            resource: 'holidays',
            action: 'read'
        },
    },
    {
        path: '/settings/settings/edit',
        name: 'settings-edit',
        component: () => import('@/views/setting/setting/settings-edit/SettingsEdit.vue'),
        meta: {
            resource: 'settings',
            action: 'read'
        },
    },
    {
        path: '/settings/paymentmethods/list',
        name: 'paymentmethods-list',
        component: () => import('@/views/setting/paymentmethod/paymentmethods-list/PaymentMethodsList.vue'),
        meta: {
            resource: 'paymentmethods',
            action: 'read'
        },
    },
    {
        path: '/settings/contract/language/list',
        name: 'contracttemplatelanguages-list',
        component: () => import('@/views/setting/contract/language/languages-list/LanguagesList.vue'),
        meta: {
            resource: 'contracttemplates',
            action: 'read'
        },
    },
    {
        path: '/settings/contract/template/list',
        name: 'contracttemplatetemplates-list',
        component: () => import('@/views/setting/contract/template/templates-list/TemplatesList.vue'),
        meta: {
            resource: 'contracttemplates',
            action: 'read'
        },
    },
    {
        path: '/settings/contract/template/edit/:id',
        name: 'templates-edit',
        component: () => import('@/views/setting/contract/template/templates-edit/TemplatesEdit.vue'),
        meta:{
          action: 'update',
          resource: 'contracttemplates'
        }
    },
    {
        path:'/settings/contract/template/create',
        name: 'templates-create',
        component: () => import('@/views/setting/contract/template/templates-edit/TemplatesEdit.vue'),
        meta:{
        action: 'create',
        resource: 'contracttemplates'
        }
    },

    



]