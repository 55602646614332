export default [
    {
        path: '/reports/user',
        name: 'reports-user',
        component: () => import('@/views/report/user/UsersList.vue'),
        meta: {
            resource: 'reports',
            action: 'read'
        }
    },
    {
        path: '/reports/client',
        name: 'reports-client',
        component: () => import('@/views/report/client/ClientsList.vue'),
        meta: {
            resource: 'reports',
            action: 'read'
        }
    },
    {
        path: '/reports/project',
        name: 'reports-project',
        component: () => import('@/views/report/project/ProjectsList.vue'),
        meta: {
            resource: 'reports',
            action: 'read'
        }
    },
    {
        path: '/reports/invoice',
        name: 'reports-invoice',
        component: () => import('@/views/report/invoice/InvoicesList.vue'),
        meta: {
            resource: 'reports',
            action: 'read'
        }
    },
    {
        path: '/reports/payment',
        name: 'reports-payment',
        component: () => import('@/views/report/receipt/PaymentsList.vue'),
        meta: {
            resource: 'reports',
            action: 'read'
        }
    },
]