export default [
    {
        path: '/products/list',
        name: 'products-list',
        component: () => import('@/views/product/products-list/ProductsList.vue'),
        meta: {
            resource: 'products',
            action: 'read'
        }

    },
    {
        path: '/suppliers/list',
        name: 'suppliers-list',
        component: () => import('@/views/supplier/suppliers-list/SuppliersList.vue'),
        meta: {
            resource: 'suppliers',
            action: 'read'
        }

    },
]