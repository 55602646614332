import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import pages from './routes/pages'
import users from './routes/users'
import clients from './routes/clients'
import settings from './routes/settings'
import invoices from './routes/invoices'
import admins from './routes/admins'
import contracts from './routes/contracts'
import projects from './routes/projects'
import tasks from './routes/tasks'
import quotations from './routes/quotations'
import meetings from './routes/meetings'
import products from './routes/products'
import reports from './routes/reports'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...pages,
    ...users,
    ...clients,
    ...settings,
    ...invoices,
    ...admins,
    ...contracts,
    ...projects,
    ...tasks,
    ...quotations,
    ...meetings,
    ...products,
    ...reports
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ! We updated login route name here from `auth-login` to `login` in starter-kit
    if (!isLoggedIn) return next({ name: 'auth-login' })
    
    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    
    next(getHomeRouteForLoggedInUser())
  }


  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
